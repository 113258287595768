import { environment } from '@env/environment';

const { apiUrl } = environment;
export const ApiPath = {
  // 메세지 전송
  postMessage: `${apiUrl}/api/chats`,
  // 메세지 응답 중지
  deleteMessage: (msgTxId: string) => `${apiUrl}/api/chats/${msgTxId}`,
};

// * ===================================================================
/** api 호출시 RQ 타입 */
export type TPostRQ = {
  channelId: string;
  msgTxId: string;
  message: string;
};
/** 화면단에서 사용하기 위한 타입 */
export type TMessageItem = TPostRQ & {
  role: 'user' | 'bot';
};
