import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
// ngrx
import { Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/reducers';
// services
import { CoreChatStore } from '../../core-chat.store';
// components
import { CoreChatChatItemComponent } from '../chat-item/chat-item.component';

@Component({
  selector: 'core-chat-chat-list',
  standalone: true,
  imports: [CommonModule, CoreChatChatItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chat-list.component.html',
})
export class CoreChatChatListComponent implements AfterViewChecked {
  private store = inject(CoreChatStore);

  @ViewChild('messageContainer') messageContainer!: ElementRef<HTMLUListElement>;

  //#region signals
  messageCount$ = this.store.messageCount;
  messageList$ = this.store.messageList;
  //#endregion

  //#region store
  userName$ = this.globalStore.select(fromAuth.selectUserName);
  //#endregion

  //#region states
  scrollToBottomYn = false;
  //#endregion

  constructor(private globalStore: Store) {
    effect(() => {
      const count = this.messageCount$();
      if (count > 0) {
        this.scrollToBottomYn = true;
      }
    });
  }

  //#region life cycle hooks
  ngAfterViewChecked(): void {
    if (this.scrollToBottomYn) {
      // ? 스크롤 액션은 화면이 새로 랜더링 되고 나서 실행되어야 하므로 ngAfterViewChecked에서 실행
      this.scrollToBottom();
      this.scrollToBottomYn = false;
    }
  }
  //#endregion

  /**
   * scroll message container to bottom
   */
  private scrollToBottom(): void {
    if (this.messageContainer && this.messageContainer.nativeElement) {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    }
  }
}
