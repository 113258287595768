<div class="core_cont">
  <h3 class="ico_comm img_chat_logo">core chat logo</h3>
  <p class="txt_corechat">How can I help you today?</p>
  <strong class="screen_out">shortcut link list</strong>
  <ul class="list_info">
    <li>
      <a class="link_info link_code" (click)="onClickQuickCommand($event, 'hs')">
        <strong class="tit_info">HS Code Finder</strong>
        <p class="txt_info">Please enter the product name to find the HS Code.<br />/hs [product name]</p>
      </a>
    </li>
    <li>
      <a class="link_info link_unipass" (click)="onClickQuickCommand($event, 'un')">
        <strong class="tit_info">Unipass</strong>
        <p class="txt_info">Please enter B/L number for import customs info.<br />/un [B/L No]</p>
      </a>
    </li>
  </ul>
</div>
