<div>
  <fieldset>
    <div class="wrap_core">
      <div class="core_top">
        <h2 class="ico_comm ico_corechat">CoreChat</h2>
        <button type="button" class="btn_comm btn_close" (click)="onClose($event)">Close CoreChat</button>
      </div>
      @if (messageCount$() > 0) {
        <core-chat-chat-list></core-chat-chat-list>
      } @else {
        <core-chat-intro></core-chat-intro>
      }
      <div class="core_bottom">
        <!--Waves Container-->
        <div class="bg_chat"></div>
        <!--Waves end-->

        <core-chat-shortcut></core-chat-shortcut>
        <core-chat-chat-input></core-chat-chat-input>
      </div>
    </div>
  </fieldset>
</div>
