import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
// components
import { HeaderComponent } from './header/header.component';
import { LnbComponent } from './lnb/lnb.component';
import { CoreChatComponent } from './core-chat/core-chat.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [CommonModule, NzDrawerModule, HeaderComponent, LnbComponent, CoreChatComponent],
  template: `
    <!-- header 영역: 헤더 컴포넌트 위치는 퍼블리싱 할때 편한 위치로 옮겨도 됨 -->
    <header class="convey_head">
      <app-header (onOpenCoreChatEvent)="onOpenCoreChatHandler()"></app-header>
    </header>
    <main class="convey_cont">
      <!-- LNB 영역 -->
      <app-lnb></app-lnb>
      <!-- contents 영역 -->
      <ng-content></ng-content>
      <!-- CoreChat 영역 -->
    </main>
    <!-- CoreChat 영역 -->
    <nz-drawer
      [nzVisible]="coreChatVisible"
      [nzWidth]="600"
      [nzClosable]="false"
      [nzKeyboard]="false"
      [nzMaskClosable]="false"
      nzPlacement="right"
    >
      <ng-container *nzDrawerContent>
        <app-core-chat (onCloseEvent)="onCloseCoreChatHandler()"></app-core-chat>
      </ng-container>
    </nz-drawer>
  `,
})
export class LayoutComponent implements AfterViewChecked {
  //#region states
  private subscription: Subscription | undefined;
  coreChatVisible = false;
  //#endregion

  //#region life cycle hooks

  ngAfterViewChecked(): void {
    if (this.coreChatVisible) {
      this.subscribeToDrawerMaskClickEvent();
    } else {
      this.unsubscribeFromDrawerMaskClickEvent();
    }
  }

  private subscribeToDrawerMaskClickEvent(): void {
    const drawerMask = document.querySelector('.ant-drawer-mask');
    if (drawerMask) {
      this.subscription = fromEvent(drawerMask, 'click')
        .pipe(takeWhile(() => this.coreChatVisible))
        .subscribe(() => {
          this.coreChatVisible = false;
        });
    }
  }

  private unsubscribeFromDrawerMaskClickEvent(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  //#endregion

  //#region event handlers
  onOpenCoreChatHandler(): void {
    this.coreChatVisible = true;
  }
  onCloseCoreChatHandler(): void {
    this.coreChatVisible = false;
  }
  //#endregion
}
