import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// services
import { ApiRequestService } from '@core/services/api/api-request.service';
// models
import { ApiPath, TPostRQ } from './core-chat.models';

@Injectable({
  providedIn: 'root',
})
export class CoreChatService {
  private hasDisplayedMessage = false;

  hasDisplayed() {
    return this.hasDisplayedMessage;
  }
  markAsDisplayed() {
    this.hasDisplayedMessage = true;
  }
  resetDisplayed() {
    this.hasDisplayedMessage = false;
  }
  constructor(private apiService: ApiRequestService) {}

  // * ===================================================================
  //#region chat
  /** send message */
  sendMessage(condition: TPostRQ): Observable<string> {
    return this.apiService.post(ApiPath.postMessage, condition).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(condition.msgTxId);
        } else {
          return throwError(() => res);
        }
      }),
    );
  }
  stopMessage(msgTxId: string): Observable<string> {
    return this.apiService.delete(ApiPath.deleteMessage(msgTxId)).pipe(
      switchMap((res) => {
        if (res.code === '200') {
          return of(msgTxId);
        } else {
          return throwError(() => res);
        }
      }),
    );
  }
  //#endregion
}
