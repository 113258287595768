<div class="lnb_aside">
  <h2 class="screen_out">
    <span class="ico_lnb">lnb 메뉴 리스트</span>
  </h2>
  <h3 class="tit_convey">
    <span class="ir_pm ico_weraser">Weraser</span>
  </h3>
  <strong class="lnb_menu_title">MENU</strong>
  <app-menu></app-menu>
</div>
