import { computed, inject } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, filter, tap } from 'rxjs/operators';
// @ngrx
import { Store } from '@ngrx/store';
import { signalStore, withState, withMethods, patchState, withHooks, withComputed } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
// services
import { CoreChatService } from './core-chat.service';
// models
import { TMessageItem } from './core-chat.models';

export const CoreChatStore = signalStore(
  { providedIn: 'root' },
  withState({
    channelId: null as string | null,
    messageList: [] as TMessageItem[],
    preparingTxIds: [] as string[],
    quickCommand: null as 'hs' | 'un' | null,
    quickCommandToggle: false,
  }),
  withComputed(({ messageList, preparingTxIds }) => ({
    messageCount: computed(() => messageList().length),
    isLoading: computed(() => preparingTxIds().length > 0),
  })),
  withMethods((state, apiService = inject(CoreChatService), store = inject(Store)) => ({
    initChannelId: (channelId: string) => {
      patchState(state, { channelId });
    },
    sendMessage: rxMethod<TMessageItem>((c$) =>
      c$.pipe(
        tap((message) => {
          patchState(state, {
            messageList: [...state.messageList(), message],
          });
        }),
        exhaustMap(({ channelId, msgTxId, message }) =>
          apiService.sendMessage({ channelId, msgTxId, message }).pipe(
            catchError((error) => {
              // alert(error?.message || 'Failed to send message');
              return EMPTY;
            }),
          ),
        ),
        tap((msgTxId) => {
          patchState(state, {
            preparingTxIds: [...state.preparingTxIds(), msgTxId],
          });
        }),
      ),
    ),
    receiveMessage: rxMethod<{ msgTxId: string; message: string }>((c$) =>
      c$.pipe(
        filter(({ msgTxId }) => state.preparingTxIds().includes(msgTxId)),
        tap(({ msgTxId, message }) => {
          patchState(state, {
            messageList: [
              ...state.messageList(),
              {
                channelId: state.channelId()!,
                msgTxId,
                message,
                role: 'bot',
              },
            ],
          });
        }),
        tap(({ msgTxId }) => {
          patchState(state, {
            preparingTxIds: state.preparingTxIds().filter((txId) => txId !== msgTxId),
          });
        }),
      ),
    ),
    stopMessage: rxMethod<string>((c$) =>
      c$.pipe(
        exhaustMap((msgTxId) =>
          apiService.stopMessage(msgTxId).pipe(
            catchError((error) => {
              // alert(error?.message || 'Failed to stop message');
              return EMPTY;
            }),
          ),
        ),
        tap((msgTxId) => {
          patchState(state, {
            // messageList: state.messageList().filter((message) => message.msgTxId !== msgTxId),
            preparingTxIds: state.preparingTxIds().filter((txId) => txId !== msgTxId),
          });
        }),
      ),
    ),
    setQuickCommand: (quickCommand: 'hs' | 'un') => {
      patchState(state, { quickCommand, quickCommandToggle: !state.quickCommandToggle() });
    },
  })),
  withHooks({
    onInit: (store) => {
      console.log('onInit CoreChatSignalStore', store);
    },
    onDestroy: (store) => {
      console.log('onDestroy CoreChatSignalStore', store);
    },
  }),
);
