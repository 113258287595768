import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
// components
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-lnb',
  standalone: true,
  imports: [CommonModule, MenuComponent],
  templateUrl: './lnb.component.html',
})
export class LnbComponent {}
