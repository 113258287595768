import { inject } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
// @ngrx
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { signalStore, withState, withMethods, patchState, withHooks } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import * as fromAuth from '@auth/reducers';

export const LayoutStore = signalStore(
  { providedIn: 'root' },
  withState({
    showCoreChat: false,
  }),
  withMethods((state) => ({
    toggleCoreChat: () => {
      patchState(state, { showCoreChat: !state.showCoreChat });
    },
  })),
  withHooks({
    onInit: (state) => {
      console.log('(onInit) SignalStore<layout>', state);
    },
    onDestroy: (state) => {
      console.log('(onDestroy) SignalStore<layout>', state);
    },
  }),
);
