import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  ViewChild,
  effect,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { v4 } from 'uuid';
// ng-zorro
import { NzInputModule } from 'ng-zorro-antd/input';
// services
import { CoreChatStore } from '../../core-chat.store';
// models
import { TMessageItem } from '../../core-chat.models';

@Component({
  selector: 'core-chat-chat-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NzInputModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chat-input.component.html',
})
export class CoreChatChatInputComponent implements AfterViewInit {
  destroyRef = inject(DestroyRef);
  private store = inject(CoreChatStore);

  @ViewChild('input') textInput!: ElementRef<HTMLTextAreaElement>;

  //#region signals
  channelId$ = this.store.channelId;
  isLoading$ = this.store.isLoading;
  quickCommand$ = this.store.quickCommand;
  quickCommandToggle$ = this.store.quickCommandToggle;
  preparingTxIds$ = this.store.preparingTxIds;
  //#endregion

  //#region states
  textValue = '';
  //#endregion

  constructor(private cdr: ChangeDetectorRef) {
    effect(() => {
      const quickCommand = this.quickCommand$();
      const quickCommandToggle = this.quickCommandToggle$();
      if (quickCommand != null) {
        this.textInput.nativeElement.focus();
        this.textValue = `/${quickCommand} `;
        this.cdr.markForCheck();
      }
    });
  }

  //#region life cycle hooks
  ngAfterViewInit() {
    fromEvent<KeyboardEvent>(this.textInput.nativeElement, 'keypress')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          this.onClickSend();
          this.cdr.markForCheck();
        }
      });
  }
  //#endregion

  //#region events
  onClickSend() {
    const message = this.textValue.trim();
    if (message.length > 0) {
      const messageItem: TMessageItem = {
        channelId: this.channelId$()!,
        msgTxId: v4(),
        message,
        role: 'user',
      };
      this.store.sendMessage(messageItem);
      this.textValue = '';
    }
  }

  onClickStop() {
    const preparingTxIds = this.preparingTxIds$();
    if (preparingTxIds.length > 0) {
      this.store.stopMessage(preparingTxIds[0]);
    }
  }
  //#endregion
}
