import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
// models
import { TMessageItem } from '../../core-chat.models';

@Component({
  selector: 'core-chat-chat-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chat-item.component.html',
})
export class CoreChatChatItemComponent {
  //#region Input/Output Properties
  @Input() message!: TMessageItem;
  @Input() userName!: string | null | undefined;
  //#endregion
}
