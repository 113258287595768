import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { delay, filter, takeUntil, tap } from 'rxjs/operators';
// ngrx
import { Store } from '@ngrx/store';
import { AuthActions } from '@app/auth/actions';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  animations: [
    // logout
    trigger('animationProfile', [
      state('opened', style({ opacity: 1 })),
      state('closed', style({ opacity: 0 })),
      transition('opened => closed', [animate('.1s')]),
      transition('closed => opened', [animate('.15s')]),
    ]),
  ],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  //#region Input/Output Properties
  @Output() onOpenCoreChatEvent = new EventEmitter<void>();
  //#endregion

  @ViewChild('menuProfile', { static: true }) menuProfile: ElementRef<HTMLLIElement> | undefined;

  // states
  private $unsubscribe = new Subject<void>();
  public menuProfileState: 'opened' | 'closed' = 'closed';
  public mouseOnProfile = false;

  constructor(private globalStore: Store) {}

  ngAfterViewInit() {
    fromEvent(this.menuProfile!.nativeElement, 'mouseover')
      .pipe(
        takeUntil(this.$unsubscribe),
        tap(() => (this.mouseOnProfile = true)),
        filter(() => this.menuProfileState === 'closed'),
      )
      .subscribe(() => {
        this.menuProfileState = 'opened';
      });
    fromEvent(this.menuProfile!.nativeElement, 'mouseout')
      .pipe(
        takeUntil(this.$unsubscribe),
        tap(() => (this.mouseOnProfile = false)),
        delay(100),
        filter(() => this.menuProfileState === 'opened' && !this.mouseOnProfile),
      )
      .subscribe(() => {
        this.menuProfileState = 'closed';
      });
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  //#region events
  onOpenCoreChat(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.onOpenCoreChatEvent.emit();
  }

  onClickLogout(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.globalStore.dispatch(AuthActions.logout());
  }
  //#endregion
}
