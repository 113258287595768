import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { v4 } from 'uuid';
// services
import { CoreChatStore } from './core-chat.store';
// components
import {
  CoreChatIntroComponent,
  CoreChatChatListComponent,
  CoreChatShortcutComponent,
  CoreChatChatInputComponent,
} from './components';

@Component({
  selector: 'app-core-chat',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CoreChatIntroComponent,
    CoreChatChatListComponent,
    CoreChatShortcutComponent,
    CoreChatChatInputComponent,
  ],
  templateUrl: './core-chat.component.html',
})
export class CoreChatComponent implements OnInit {
  //#region Input/Output Properties
  @Output() onCloseEvent = new EventEmitter<void>();
  //#endregion

  private store = inject(CoreChatStore);

  //#region signals
  messageCount$ = this.store.messageCount;
  //#endregion

  constructor() {}

  //#region life cycle hooks
  ngOnInit(): void {
    this.store.initChannelId(v4());
  }
  //#endregion

  //#region events
  onClose(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault;

    this.onCloseEvent.emit();
  }
  //#endregion
}
