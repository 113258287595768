import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
// services
import { CoreChatStore } from '../../core-chat.store';

@Component({
  selector: 'core-chat-intro',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './intro.component.html',
})
export class CoreChatIntroComponent {
  private store = inject(CoreChatStore);

  //#region events
  onClickQuickCommand(event: MouseEvent, command: 'hs' | 'un') {
    event.defaultPrevented;
    event.stopPropagation();

    this.store.setQuickCommand(command);
  }
  //#endregion
}
