@if (message) {
  <li>
    <div class="box_chat" [class.box_convey]="message.role === 'bot'">
      <span class="img_comm img_user">프로필 이미지</span>
      <span class="chat_right">
        <span class="chat_user">{{ message.role === 'bot' ? 'WiseConvey' : userName }}</span>
        <span class="chat_sub">{{ message.message }}</span>
      </span>
    </div>
  </li>
}

<!-- user chat -->
<!-- <li>
  <div class="box_chat box_convey">
    <span class="img_comm img_user">image</span>
    <span class="chat_right">
      <span class="chat_user">WiseConvey</span>
      <span class="chat_sub">How can I help you today?</span>
    </span>
  </div>
</li> -->
<!-- ai chat -->
<!-- <li>
  <div class="box_chat">
    <span class="img_comm img_user">image</span>
    <span class="chat_right">
      <span class="chat_user">User Name</span>
      <span class="chat_sub">Find the Hs Code.</span>
    </span>
  </div>
</li> -->
