<div class="box_chat">
  <label class="screen_out">shortcut chat textarea</label>
  <span class="inp_chat">
    <textarea
      #input
      nz-input
      [(ngModel)]="textValue"
      placeholder="Message CoreChat"
      [readOnly]="isLoading$()"
    ></textarea>

    @if (isLoading$()) {
      <button type="button" class="btn_comm btn_pause" (click)="onClickStop()">일시정지</button>
    } @else {
      <button
        type="button"
        class="btn_comm btn_enter"
        [class.on_enter]="textValue.length > 0"
        (click)="onClickSend()"
      >
        입력
      </button>
    }
  </span>
</div>
