import { Component, ViewContainerRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, pairwise } from 'rxjs/operators';
import { isNil, isString } from 'lodash-es';
import { environment } from '@env/environment';
// sentry
import * as Sentry from '@sentry/angular-ivy';
// @ngrx/store
import { Store } from '@ngrx/store';
import { selectUrl } from '@app/reducers';
import * as fromAuth from '@app/auth/reducers';
// @ngrx/signals
import { LayoutStore } from '@shared/services/layout.store';
import { CoreChatStore } from '@shared/components/core-chat/core-chat.store';
// services
import { SseService } from '@core/services/sse/sse.service';
import { AppService } from './app.service';
// models
import { EventSourceMessage } from '@microsoft/fetch-event-source';
// components
import { LayoutComponent } from '@shared/components/layout.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private layoutStore = inject(LayoutStore);
  private coreChatStore = inject(CoreChatStore);

  withoutLayoutPages: string[] = ['', '/', '/login', '/not-found', '/terms-of-service', '/privacy-policy'];

  // signals
  showCoreChat = this.layoutStore.showCoreChat;

  // states
  withoutLayoutYn$: Observable<boolean | undefined>;
  token$ = this.store.select(fromAuth.selectUserAccessToken);
  userInfo$ = this.store.select(fromAuth.selectUserInfo);

  constructor(
    private store: Store,
    private sseService: SseService,
    private viewContainerRef: ViewContainerRef,
    private appService: AppService,
  ) {
    this.appService.setAppViewContainer(this.viewContainerRef);
    // 레이아웃 사용여부
    this.withoutLayoutYn$ = this.store.select(selectUrl).pipe(
      map((url) => {
        if (isNil(url)) {
          // undefined일 경우, 아무런 요소도 랜더링 하지 않는다.
          return undefined;
        }

        return this.withoutLayoutPages.includes(url);
      }),
    );

    // check login page, when true add class 'wise_login' to body
    this.store.select(selectUrl).subscribe((url) => {
      if (url === '/login') {
        document.body.classList.add('wise_login');
      } else {
        document.body.classList.remove('wise_login');
      }
    });

    // sse
    combineLatest([this.token$, this.sseService.connectFlag$])
      .pipe(
        filter(([token, connectFlag]) => !!token && connectFlag),
        debounceTime(2000),
      )
      .subscribe(([token]) => {
        this.sseService.fetchEventSource('/api/sse/subscribe', token!, this.onSseMessage);
      });

    // sse close -> case of logout
    this.token$.pipe(pairwise()).subscribe(([prevToken, currToken]) => {
      if (isString(prevToken) && prevToken?.length > 0 && isNil(currToken)) {
        this.sseService.closeEventSource();
        this.sseService.setConnectFlag(true);
      }
    });
    // sentry
    this.userInfo$
      .pipe(
        filter((userInfo) => !!userInfo),
        filter(() => environment.production),
      )
      .subscribe((userInfo) => {
        Sentry.getCurrentScope().setUser({
          email: userInfo?.email,
          name: userInfo?.name,
        });
      });
  }

  // sse handler
  onSseMessage = (ev: EventSourceMessage) => {
    const { data, event } = ev;
    switch (event) {
      case 'corechat':
        // console.log('sse corechat', ev);
        if (data?.length > 0) {
          const json = JSON.parse(data);
          const { msgTxId, message, redirectTo } = json || {};
          this.coreChatStore.receiveMessage({ msgTxId, message });
        }
        break;
      default:
        // console.log('sse onmessage', event, ev.id);
        break;
    }
  };
}
